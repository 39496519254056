<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img  
              :src="imagem_fundo"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
              </v-toolbar>

              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 

              <v-row v-if="!loading && !permite_simular">
                <v-col cols="12" >
                  <v-card-text>
                    <v-alert class="text-h5" text type="info" color="blue darken-4" >{{mensagem}}</v-alert>
                  </v-card-text>
                </v-col>
              </v-row> 


            <v-form ref="form" v-model="form_valido" lazy-validation> 
              <!-- 
              ####################################################
              # Painel de entrada do simulador simplificado
              ####################################################
              -->
        
              <v-row v-if="!loading && permite_simular">
                <v-col cols="12" md="6" v-if="simulador == 1">
                  <v-card-text>    

                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light mt-n1">Idade na aposentadoria:</div>
                      </v-col>
                      <v-col cols="12"  md="5">
                        <div class="text-h5  ml-4 text-right mt-n1" v-text="idade_aposentadoria+' anos'" ></div>
                      </v-col>
                    </v-row>    
                    
                    <v-row>
                      <v-col cols="12">
                        <v-slider
                          v-model="idade_aposentadoria"
                          :min="idade_min"
                          :max="idade_max"
                          height="30"
                          class="mt-n4"
                        >

                        </v-slider>
                      </v-col>
                    </v-row> 
                    
                    
                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light mt-n4">Contribuição básica + variável:</div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="text-h5 ml-4 text-right mt-n4" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_basica + valor_variavel)" ></div>
                      </v-col>
                    </v-row> 

                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="font-weight-light ml-1 mt-n4">Percentual de contribuição variável:</div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="text-h5 font-weight-light ml-4 text-right mt-n4" v-text="'('+percentual+'%)'" ></div>
                      </v-col>
                    </v-row>  
                    
                    <v-row>
                      <v-col cols="12">
                        <v-slider
                          v-model="percentual"
                          :min="percentual_min"
                          :max="percentual_max"
                          height="30"
                          :disabled="remuneracao <= (vrs * 8)"
                          class="mt-n4"
                          @change="alteraVariavel"
                        >

                        </v-slider>
                      </v-col>
                    </v-row> 

                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light mt-n4">Contribuição espontânea:</div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="text-h5 ml-4 text-right mt-n4" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_espontanea)" ></div>
                      </v-col>
                    </v-row> 

                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="font-weight-light ml-1 mt-n4">Percentual:</div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="text-h5 font-weight-light ml-4 text-right mt-n4" v-text="'('+Math.round(percentual_espontanea * 100)/100+'%)'" ></div>
                      </v-col>
                    </v-row> 

                    <v-row>
                      <v-col cols="12">
                        <v-slider
                          :min="percentual_espontanea_min"
                          :max="percentual_espontanea_max"
                          height="30"
                          class="mt-n4"
                          :value="percentual_espontanea"
                          @change="alteraEspontanea"
                        >

                        </v-slider>
                      </v-col>
                    </v-row> 

                   

                    <v-row v-if="alteracao_contrib != 's' ">
                      <v-col cols="12">
                        <v-alert class="text-justify" text  color="blue darken-4" ><div class="grey--text text--darken-2">Para alterar sua contribuição, faça uma simulação com a contribuição desejada e em seguida clique aqui: <a class="indigo--text text--darken-2" href="#" @click="confirmaAlteracaoContribuicao();">{{desc_contrib}}</a>. Caso o simulador simplificado não permita escolher os valores exatos que você deseja, tente o simulador avançado.</div></v-alert>
                      </v-col>
                    </v-row>

                    <v-row v-if="alteracao_contrib == 's' ">
                      <v-col cols="12">
                        <v-alert class="text-justify " text color="blue darken-4" ><div class="grey--text text--darken-2">Caso o simulador simplificado não permita escolher os valores exatos que você deseja, tente o simulador avançado.</div></v-alert>
                      </v-col>
                    </v-row> 

                    <v-row>
                      <v-col cols="12" md="6" class="text-center ">
                        <v-btn color="tertiary" rounded dark @click="TrocaSimulador">{{botao_simulador}}</v-btn>
                      </v-col>
                      <v-col cols="12" md="6" class="text-center ">
                        <v-btn color="primary" rounded dark @click="SimulacaoAvancada">Executar Simulação</v-btn>
                      </v-col>
                    </v-row>
                    
                    

                    

                    
                  </v-card-text>
                </v-col>
                
                <!-- 
                ####################################################
                # Painel de entrada do simulador avançado
                ####################################################
                -->


                  <v-col cols="12" md="6" v-if="simulador == 2">
                    <v-card-text >     
                      <v-row class="pb-0">
                        <v-col cols="12"  md="6" class="pb-0">
                          <v-select
                          outlined
                          dense
                          v-model="idade_aposentadoria"
                          :items="lista_idade_avancado"
                          item-text="descricao"
                          item-value="id"
                          label="Idade na aposentadoria"
                          ></v-select>
                        </v-col>
                        <v-col cols="12"  md="6" class="pb-0">
                          <v-select
                          outlined
                          dense
                          v-model="idade_meses"
                          :items="lista_meses_avancado"
                          item-text="descricao"
                          item-value="id"
                          :rules="[...idade_minima]" 
                       
                          ></v-select>
                        </v-col>
                      </v-row>    

                      <v-row class="py-0">
                        <v-col cols="12"  md="6" class="py-0">
                          <v-select
                          outlined
                          dense
                          v-model="opcao_variavel"
                          :items="opcoes_variavel"
                          item-text="descricao"
                          item-value="id"
                          @input="zeraVariavel"
                          label="Contrib. variável em R$ ou %"
                          ></v-select>
                        </v-col>
                        <v-col cols="12"  md="6" class="py-0">
                          <v-text-field
                            outlined
                            dense
                            v-model="valor_variavel"
                            type="number"
                            hide-spin-buttons
                            :rules="[...maxVariavel]"
                            reverse
                            v-if="opcao_variavel==1"
                            label="Contribuição Variável"
                          
                          >

                          </v-text-field>
                          <v-text-field
                            outlined
                            dense
                            v-model="percentual"
                            type="number"
                            hide-spin-buttons
                            
                            :rules="[...maxVariavel]"
                            v-if="opcao_variavel==2"
                          ></v-text-field>
                        </v-col>
                      </v-row > 


                      <v-row class=" py-0">
                        <v-col cols="12" md="6" class="py-0">
                          <v-text-field
                            outlined
                            dense
                            :value="contribuicao_basica.toFixed(2)"
                            type="number"
                            hide-spin-buttons
                            readonly
                            filled
                            reverse
                            label="Contribuição basica"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12"  md="6" class="py-0">
                          <v-text-field
                            outlined
                            dense
                            v-model="valor_espontanea"
                            type="number"
                            hide-spin-buttons
                            :rules="[...maxEspontanea]"
                            reverse
                            label="Contribuição Espontânea Mensal"
                          ></v-text-field>
                        </v-col>
                      </v-row>  
                       
                      <v-row class="py-0">
                        <v-col cols="12"  md="4" class="py-0">
                          
                        </v-col>
                      </v-row>
                      <v-row class="py-0">
                        <v-col cols="12"  md="6" class="py-0">
                          <v-text-field
                            outlined
                            dense
                            v-model="contrib_total_avancada.toFixed(2)"
                            type="number"
                            hide-spin-buttons
                            readonly
                            filled
                            reverse
                            label="Contribuição Mensal Total"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12"  md="6" class="py-0">
                          <v-text-field
                            outlined
                            dense
                            v-model="contribuicao_esporadica"
                            type="number"
                            hide-spin-buttons
                            reverse
                            label="Contribuição Esporádica"
                          ></v-text-field>
                        </v-col>
                      </v-row>  


                      <v-row class="py-0">
                        <v-col cols="12" md="6" class="py-0">
                          <v-text-field
                            outlined
                            dense
                            v-model="parcela_vista_25"
                            type="number"
                            hide-spin-buttons
                            :rules="[...max25]"
                            reverse
                            label="Parcela à vista 25%"
                            prepend-icon="mdi-help-circle"

                          >
                          <template v-slot:prepend>
                            <v-tooltip
                              bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                  mdi-help-circle
                                </v-icon>
                              </template>
                              Parcela à vista até 25% sobre o saldo de contas: Reserva de poupança tributável, <br> 
                              Reserva de poupança não tributável, Complemento reserva de transferência, <br>
                              Contribuição básica e Contribuição variável. 
                            </v-tooltip>
                          </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12"  md="6" class="py-0">
                          <v-text-field
                            outlined
                            dense
                            v-model="parcela_vista_100"
                            type="number"
                            hide-spin-buttons
                            :rules="[...max100]"
                            reverse
                            label="Parcela à vista 100%"
                            prepend-icon="mdi-help-circle"

                          >
                          <template v-slot:prepend>
                            <v-tooltip
                              bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                  mdi-help-circle
                                </v-icon>
                              </template>
                              Parcela à vista até 100% sobre o saldo de contas: <br>
                              Contribuição espontâneas e Recursos portados.
                            </v-tooltip>
                          </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="py-0" v-if="tipo_tributacao == 'R'">
                        <v-col cols="12" md="6" class="py-0">
                           <v-text-field
                            outlined
                            dense
                            v-model="prazo_pmp"
                            type="number"
                            hide-spin-buttons
                            readonly
                            filled
                            reverse
                            label="Prazo de acumulação PMP"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" class="py-0">
                           <v-text-field
                            outlined
                            dense
                            v-model="aliquota_str"
                            hide-spin-buttons
                            readonly
                            filled
                            reverse
                            label="Alíquota"
                            
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      
                      <v-row class="mt-n6 pt-0 pb-0">
                        <v-col cols="12" class="py-0">
                          <v-checkbox
                            v-model="usa_crecimento"
                          >
                          <template v-slot:label>
                              <div class="font-weight-light">Utilizar premissa de crescimento salarial médio ({{Intl.NumberFormat('pt-BR',{style:'percent', minimumFractionDigits: 2}).format(crescimento_salarial)}} a.a)</div>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>  

                      <v-row v-if="alteracao_contrib != 's' " class="mt-n6 pt-0 pb-0">
                      <v-col cols="12">
                        <v-alert class="text-justify" text  color="blue darken-4" ><div class="grey--text text--darken-2">Para alterar sua contribuição, faça uma simulação com a contribuição desejada e em seguida clique aqui: <a class="indigo--text text--darken-2" href="#" @click="confirmaAlteracaoContribuicao();">{{desc_contrib}}</a>.</div></v-alert>
                      </v-col>
                    </v-row>       
                      

                      <v-row class="py-0">
                        <v-col cols="12" md="6" class="text-center py-0">
                          <v-btn color="tertiary" rounded dark @click="TrocaSimulador">{{botao_simulador}}</v-btn>
                        </v-col>
                        <v-col cols="12" md="6" class="text-center py-0">
                          <v-btn color="primary" rounded dark @click="SimulacaoAvancada">Executar Simulação</v-btn>
                        </v-col>
                      </v-row>
                  
                    </v-card-text>
                  </v-col>
                
                <!-- 
                ####################################################
                # Painel de resultado da simulação
                ####################################################
                -->

                <v-col cols="12" md="6">
                  <v-card-text>     
                    <v-row>
                      <v-col cols="12" md="6">
                        <span class="text-h5  " >Saldo Projetado</span>
                      </v-col>
                      <v-col cols="12" md="6" >
                        <div class="text-center" v-if="loading_fator"> 
                          <v-progress-linear
                            class="mt-3"
                            indeterminate
                            color="grey"
                            striped
                            height="20"
                          ></v-progress-linear>
                        </div>
                        <div v-if="!loading_fator" class="text-h5  mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(saldo_projetado)"></div>
                      </v-col>
                    </v-row>  
                     
                    <v-row>
                      <v-col cols="12" md="6">
                        <span class="text-h5  ">Aposentadoria Projetada</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-center" v-if="loading_fator">
                          <v-progress-linear
                            class="mt-3"
                            indeterminate
                            color="grey"
                            striped
                            height="20"
                          ></v-progress-linear>
                        </div>
                        <div v-if="!loading_fator" class="text-h5  mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(beneficio)"></div>
                      </v-col>
                    </v-row>   
                    <v-row>
                      <v-col cols="12" md="6">
                        <span class="text-h5 font-weight-light ">Data de aposentadoria</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="data_aposentadoria.substr(8,2)+'/'+data_aposentadoria.substr(5,2)+'/'+data_aposentadoria.substr(0,4)"></div>
                      </v-col>
                    </v-row> 
                    <v-row>
                      <v-col cols="12" md="6">
                        <span class="text-h5 font-weight-light ">Parcela à vista 25%</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(parcela_vista_25_projetada)"></div>
                      </v-col>
                    </v-row> 
                    <v-row>
                      <v-col cols="12" md="6">
                        <span class="text-h5 font-weight-light ">Parcela à vista 100%</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(parcela_vista_100_projetada)"></div>
                      </v-col>
                    </v-row> 
                    <v-row v-if="parcela_vista_25_projetada > 0 || parcela_vista_100_projetada > 0">
                      <v-col cols="12" md="6">
                        <span class="text-h5 font-weight-light ">Saldo resultante</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(saldo_resultante)"></div>
                      </v-col>
                    </v-row> 
                    <br>

                    <v-row v-if="1==1">
                      <v-col cols="12" class="mb-n1">
                        <v-alert class="text-justify" text  color="blue darken-4" >
                          <div class="grey--text text--darken-2">
                            <p>   
                              <b class="grey--text text--darken-2">Opção atual pelo regime tributário:</b> {{ tipo_tributacao =="P" ? "Progressiva" : "Regressiva"}} (*)
                            </p>
                            <p class="font-weight-light text-justify"> 
                              (*)Se não tiver realizado a opção pelo regime de tributação ou tenha realizado antes de 11/01/2024 (Lei 14.803/2024), esta opção poderá ser realizada no momento da solicitação da aposentadoria.
                              Abaixo mostramos a simulação de tributação nas duas modalidades (REGRESSIVA e PROGRESSIVA).
                            </p>
                            <p> 
                              <b>IR Regressivo:  {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(ir_regressivo)}}</b>
                            </p>
                            <p> 
                              Prazo de acumulação PMP: {{Intl.NumberFormat('pt-BR',{minimumFractionDigits: 2}).format(prazo_pmp)}} ; Alíquota: {{Intl.NumberFormat('pt-BR',{minimumFractionDigits: 2}).format(aliquota_pmp)}}% 
                            </p>
                            <p> 
                              <b>IR Progressivo: {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(ir_progressivo)}}</b>
                            </p>
                            <p> 
                              Alíquota: {{Intl.NumberFormat('pt-BR',{minimumFractionDigits: 2}).format(aliquota_ir)}}% ; Alíquota efetiva de IR: {{Intl.NumberFormat('pt-BR',{minimumFractionDigits: 2}).format(aliquota_efetiva)}}%
                            </p>
                            <p class="text-justify red--text text--darken-3">
                              O valor do IR progressivo é uma simples simulação considerando apenas o valor da Aposentadoria Projetada e sua idade. 
                              Para apurar a alíquota real sugerimos a utilização do <a href="https://www27.receita.fazenda.gov.br/simulador-irpf">simulador de IR da Receita Federal</a> e nele incluir todos os seus Rendimentos Tributáveis (Benefícios INSS, Benefício SERPROS, etc) e todas as suas Deduções.
                            </p>
                          </div>
                        </v-alert>

                      </v-col>
                    </v-row>

                    <br>
                    
                    <v-row>
                      <v-col cols="12" class="mt-n5">
                        <p class="font-weight-light text-justify"> 
                          A aposentadoria a ser obtida dependerá das contribuições de fato vertidas durante todo o período de capitalização 
                          e da rentabilidade obtida pelo Serpros. As informações aqui contidas, não produzem efeitos legais. 
                          Tratam-se de estimativas e, portanto, estão sujeitas a alterações.
                          Para concessão de aposentadoria será obrigatório o cumprimento de todos os requisitos de elegibilidade 
                          (Aposentadoria no INSS, Idade, Tempo de Contribuição ao Plano e Cessação de Vinculo ao Patrocinador).
                          <span v-if="pagamento_unico">Conforme disposto no Art. 85 será pago de forma única os valores referentes ao Saldo de Contas 
                          uma vez que a renda de aposentadoria projetada seja inferior a 50% do VRS vigente na data do cálculo.</span>
                        </p>

                        <p >   
                          <b class="grey--text text--darken-2">Informações Gerais para a Base de Cálculo:</b>
                        </p>

                        <p class="font-weight-light text-justify">   
                          Data de referência: {{ data_referencia.substr(8,2)+'/'+data_referencia.substr(5,2)+'/'+data_referencia.substr(0,4) }};
                          Idade atual: {{idade}} anos e {{idade_meses_real}} meses;
                          Previsão da rentabilidade anual:   {{ Intl.NumberFormat('pt-BR').format(parametros_fator.taxa_juros)  }} %;
                          Saldo de contas atual: (Posição de {{ data_cota.substr(8,2)+'/'+data_cota.substr(5,2)+'/'+data_cota.substr(0,4) }}) {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(saldo25 + saldo100 + saldoPatroc)}}; 
                          Salário de contribuição no PSII: {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(remuneracao)}};
                         <!-- Fator atuarial: {{ fator }}; -->
                          Para esse cálculo {{usa_crecimento ? '' : 'não'}} foi considerado o crescimento salarial médio; 
                          Nesse cálculo foi utilizada uma família constituída de: {{info_dependentes}}
                        </p>
                      </v-col>
                    </v-row>
                  <!--  </v-card-text>
                  </v-card>  -->
                  </v-card-text>
                </v-col>
              </v-row>   
            </v-form>


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao && !somente_leitura"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>


<!-- Esse style é pra formatar os v-text-field do simulador avançado com o texto alinhado a direita e o label alinhado a esquerda -->
<style lang="scss" scoped>

div ::v-deep .v-label.v-label--active.theme--light {
  left: 0 !important;
  right: auto !important;
  transform-origin: top left !important;
}

div ::v-deep .v-input__slot fieldset legend {
  margin-left: 0px !important;
  width: auto;
}

</style>


<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo_pagina: "",
            titulo: "",
            imagem_fundo: process.env.VUE_APP_URL+"/img/paiefilho.png",
            botao_simulador: "Simulador Avançado",
            simulador: 1,
            opcao_variavel: 1,
            opcoes_variavel:[
              {id:1, descricao:"R$"},
              {id:2, descricao:"%"},
            ],
            form_valido: true,
            loading: true,
            loading_fator: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            idade: 0,
            idade_aposentadoria: 0,
            idade_min: 0,
            idade_max: 0,
            percentual: 0,
            percentual_original: 0,
            percentual_min: 0,
            percentual_max: 15,
            percentual_espontanea: 0,
            percentual_espontanea_min: 0,
            percentual_espontanea_max: 15,
            parcela_vista_25: 0,
            parcela_vista_100: 0,
            parcela_vista_25_projetada: 0,
            parcela_vista_100_projetada: 0,
            saldo: 0,
            saldo25: 0,
            saldo100: 0,
            saldoPatroc: 0,
            beneficio: 0,
            fator: -1,
            contribuicao_participante: 0,
            parcela_25_projetada: 0,
            parcela_100_projetada: 0,
            remuneracao: 0,
            vrs: 0,
            contribuicao_basica: 0,
            contribuicao_esporadica: 0,
            data_referencia: '',
            rentabilidade_anual: 0,
            crescimento_salarial: 0,
            usa_crecimento: true,
            rentabilidade_mensal: 0,
            saldo_inicial: 0,
            saldo_projetado: 0,
            saldo_projetado_25: 0,
            saldo_projetado_100: 0,
            saldo_resultante:0,
            data_nascimento: '',
            data_aposentadoria: '',
            ano_aposentadoria: 0,
            mes_aposentadoria: 0,
            Ano_Nasc: 0,
            Mes_Nasc: 0,
            Ano_Ref: 0,
            Mes_Ref: 0,
            parametros_fator:{},
            lista_idade_avancado: [],
            lista_meses_avancado: [],
            idade_meses: 0,
            valor_variavel: 0,
            valor_espontanea: 0,
            valor_espontanea_original: 0,
            max_variavel: 0,
            max_espontanea: 0,
            data_saldo: '',
            data_cota: '',
            idade_meses_real: 0,
            info_dependentes: "",
            maioridade: 0,
            alteracao_contrib: "",
            desc_contrib: "",
            prazo_pmp: 0,
            aliquota_pmp:0,
            aliquota_str:"",
            permite_simular: false,
            tipo_tributacao: "",
            TemPlano: false,
            pagamento_unico:false,
            aviso_25: false,
            aviso_100: false,
            somente_leitura: true,
            ir_regressivo: 0,
            ir_progressivo: 0,
            aliquota_ir: 0,
            aliquota_efetiva: 0,


         }
      },

      created: function() {

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        if(this.$route.params.tipo == "e"){
          this.titulo_pagina = "Alteração da contribuição espontânea mensal";
          this.desc_contrib = "Alterar contribuição espontânea mensal";
        }else if(this.$route.params.tipo == "v"){
          this.titulo_pagina = "Alteração da contribuição variável";
          this.desc_contrib = "Alterar contribuição variável";
        }else{
          this.titulo_pagina = "Simulador de aposentadoria";
          this.desc_contrib = "";
        }

        this.alteracao_contrib = this.$route.params.tipo;


        let params = {
            api: this.$API,
            descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params); 


         

        this.iniciaSimulador();

        
      },

      watch: {

        percentual(newPercentual) {
          if(this.simulador == 1){
            //this.valor_variavel = this.calculaVariavel(this.remuneracao, this.vrs, newPercentual);
          }  
        },
        percentual_espontanea(newPercentual) {
           // this.valor_espontanea = this.calculaEspontanea(this.remuneracao, newPercentual);
          
        },

      },

      computed: {
        contrib_total_avancada: {
          get() {
              var contrib_basica = 0;
              var contrib_variavel = 0;
              var contrib_espontanea = 0;

              contrib_basica = this.calculaBasica(this.remuneracao);

              if(this.opcao_variavel == 1){
                if(this.valor_variavel == '' || this.valor_variavel == null){
                    contrib_variavel = 0;
                }else{
                    contrib_variavel = parseFloat(this.valor_variavel);
                    this.percentual = contrib_variavel * 100 / (this.remuneracao - (8 * this.vrs));
                    this.percentual = this.percentual.toFixed(4);
                }  
              }else{
                contrib_variavel = this.calculaVariavel(this.remuneracao, this.vrs, this.percentual);
              }

              if(this.valor_espontanea == '' || this.valor_espontanea == null){
                contrib_espontanea = 0;
              }else{
                contrib_espontanea = parseFloat(this.valor_espontanea);
              } 
                
              
              return contrib_basica + contrib_variavel + contrib_espontanea - this.calculaAdm(contrib_basica + contrib_variavel); 

          }
          
        }
      },
            


 
      methods: {

        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
           
           this.sair();
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';

          this.alterarContribuicao();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          var usuario = {
                logado: false,
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        },  

        //######################################
        //# Regras de validação
        //###################################### 
        maxVariavel: function(valor) {
          
          let aprovado = true;
          let mensagem = '';
          let max = 0;

          if(this.opcao_variavel == 1){
             max = this.max_variavel;
          }else{
             max = 15;
          }

          if(valor > max){
            aprovado = false;
            mensagem = 'Valor máximo: '+ (Math.floor(max * 100) / 100).toFixed(2) ; 
          }

          return  aprovado || mensagem
        },


        maxEspontanea: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(valor > this.max_espontanea){
            aprovado = false;
            mensagem = 'Valor máximo: '+  (Math.floor(this.max_espontanea * 100) / 100).toFixed(2) ;
          }

          return  aprovado || mensagem
        },

        max25: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(valor > 25){
            aprovado = false;
            mensagem = 'Valor máximo: 25,00';
          }

          return  aprovado || mensagem
        },

        max100: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(valor > 100){
            aprovado = false;
            mensagem = 'Valor máximo: 100,00'
          }

          return  aprovado || mensagem
        },

        idade_minima: function() {  

          
          let aprovado = true;
          let mensagem = '';

          if(this.idade_aposentadoria <= this.idade){
            if(this.idade_meses < this.idade_meses_real){
              aprovado = false;
              mensagem = 'Idade mínima: '+this.idade+' anos e '+this.idade_meses_real+' meses';
            }

          }

          return  aprovado || mensagem
        },


        calcula_idade(data_nascimento, data_referencia) {

            let d1 = new Date(data_nascimento),
            ano_nasc = d1.getFullYear(),
            mes_nasc = d1.getMonth()+ 1,
            dia_nasc = d1.getDate(),
            d2 = new Date(data_referencia),
            ano_ref = d2.getFullYear(),
            mes_ref = d2.getMonth() + 1,
            dia_ref = d2.getDate(),
            idade = 0;

            ano_nasc = +ano_nasc;
            mes_nasc = +mes_nasc;
            dia_nasc = +dia_nasc;

            idade = ano_ref - ano_nasc;

            if (mes_ref < mes_nasc || mes_ref == mes_nasc && dia_ref < dia_nasc) {
                idade--;
            }

            return idade < 0 ? 0 : idade;
        },

        //######################################
        //# Funcoes genéricas
        //###################################### 

        zeraVariavel(){
           this.valor_variavel = 0;
        },

        //######################################
        //# Inicia o simulador
        //###################################### 

        iniciaSimulador(){

          for (let i = 0; i < 12; i++) {
            this.lista_meses_avancado.push({id:i, descricao: i+' meses'});
          }

          var plano = null;

          for(let i=0, len=this.$store.state.planos_usuario.length; i<len; i++){
            if(this.$store.state.planos_usuario[i].id_plano == 2 && (this.$store.state.planos_usuario[i].situacao =="ATIVO" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="BPD" || 
                                                                     this.$store.state.planos_usuario[i].situacao =="AUTOPATROCÍNIO" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="AUXÍLIO DOENÇA" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="ACIDENTE DE TRABALHO")){
              
              plano = this.$store.state.planos_usuario[i];

            }
          }

          

          if(plano != null){

            this.permite_simular = true;

            this.tipo_tributacao = plano.tipo_tributacao;

            var params = {
              id_pessoa: plano.id_pessoa,
              data_nascimento: this.$store.state.pessoa_fisica.data_nascimento,
              tipo_tributacao: plano.tipo_tributacao
            };


            this.$API.post( '/api/simulador/preparaps2', params)
            .then(response => {

                this.rentabilidade_anual = parseFloat(response.data.taxa_juros);
                this.crescimento_salarial = parseFloat(response.data.crescimento_salarial);

                this.rentabilidade_mensal = Math.pow((this.rentabilidade_anual/100 + 1),(1 / 12)) -1;

                this.idade = response.data.idade;
                this.idade_meses_real = response.data.idade_meses;

                if(this.idade < 50){
                    this.idade_min = 50;
                    this.idade_max = 80;
                }else{
                    this.idade_min = this.idade;
                    this.idade_max = this.idade + 30;
                }

                let idade_aposent = 55;

                if(this.idade <= idade_aposent){
                    this.idade_aposentadoria = idade_aposent;
                }else{
                    this.idade_aposentadoria = this.idade;
                }

                if(this.idade > 79){
                  this.idade_aposentadoria = this.idade;
                  this.idade_min = this.idade;
                  this.idade_max = this.idade + 5;
                }

                this.lista_idade_avancado = [];
                for (let i = this.idade_min; i <= this.idade_min + 30; i++) {
                  this.lista_idade_avancado.push({id:i, descricao: i+' anos'});
                }
                
                this.percentual = response.data.percentual_variavel;
                this.percentual_original = this.percentual;


                this.remuneracao = response.data.salario;
                this.vrs = response.data.vrs;
                if(this.remuneracao > 0){
                  this.contribuicao_basica = this.calculaBasica(this.remuneracao);
                  this.valor_variavel = this.calculaVariavel(this.remuneracao, this.vrs, response.data.percentual_variavel);
                  this.max_variavel = this.calculaVariavel(this.remuneracao, this.vrs, 15);
                  this.max_espontanea = this.remuneracao * 0.15;
                  this.max_espontanea = parseFloat(this.max_espontanea.toFixed(2));
                }else{
                  this.contribuicao_basica = 0;
                  this.valor_variavel = 0;
                  this.max_variavel = 0;
                  this.max_espontanea = 0;
                }
                
                this.data_referencia = response.data.data_referencia;
                this.data_nascimento = this.$store.state.pessoa_fisica.data_nascimento;
                this.Ano_Nasc = parseInt(this.data_nascimento.substr(0,4));
                this.Mes_Nasc = parseInt(this.data_nascimento.substr(5,4));
                this.mes_aposentadoria = this.Mes_Nasc;
                this.Ano_Ref = parseInt(this.data_referencia.substr(0,4));
                this.Mes_Ref = parseInt(this.data_referencia.substr(5,4));
                this.data_saldo = response.data.data_saldo;
                this.data_cota = response.data.data_cota;
                this.maioridade = response.data.maioridade;
                this.valor_espontanea_original = parseFloat(response.data.valor_espontanea);
                this.valor_espontanea = this.valor_espontanea_original;
                if(this.remuneracao > 0){
                  this.percentual_espontanea = this.valor_espontanea * 100 / this.remuneracao;
                }else{
                  this.percentual_espontanea = 0;
                }
                this.prazo_pmp = response.data.pmp;
                this.aliquota_pmp = parseFloat(response.data.aliquota_pmp);
                this.aliquota_str = this.aliquota_pmp/100;
                this.aliquota_str = Intl.NumberFormat('pt-BR',{style:'percent', minimumFractionDigits: 2}).format(this.aliquota_str);
                
                //this.saldo = response.data.saldo;  
                this.saldo25 = 0;
                this.saldo100 = 0;
                this.saldoPatroc = 0;

                for (let i = 0; i < response.data.saldo.length; i++) {

                  if(response.data.saldo[i].id_conta == 6 || response.data.saldo[i].id_conta == 23 || response.data.saldo[i].id_conta == 24) { 
                    this.saldo100 = this.saldo100 + response.data.saldo[i].valor; 
                  }else if(response.data.saldo[i].id_conta == 30 || response.data.saldo[i].id_conta == 10){
                    this.saldoPatroc = this.saldoPatroc + response.data.saldo[i].valor;
                  }else{
                    this.saldo25 = this.saldo25 + response.data.saldo[i].valor;
                  } 
                  
                } 

                let dep_aux = [];

                for(let i=0, x=response.data.dependentes.length; i<x; i++){
                    if(response.data.dependentes[i].beneficiario == "S"){
                      if(response.data.dependentes[i].vitalicio){
                        dep_aux.push(response.data.dependentes[i]);
                      }else{
                        if(response.data.dependentes[i].idade <= this.maioridade){
                          dep_aux.push(response.data.dependentes[i]);
                        }
                      }
                        
                    }
                }

                this.parametros_fator = {
                    cota_familiar: response.data.cota_familiar,
                    cota_individual_pensao: response.data.cota_individual_pensao,
                    taxa_juros: response.data.taxa_juros,
                    vrs: response.data.vrs,
                    freq: response.data.freq,
                    id_tabua: response.data.id_tabua,
                    maioridade: response.data.maioridade,
                    id_plano: 2,
                    data_referencia: this.data_aposentadoria,
                    data_nascimento: this.$store.state.pessoa_fisica.data_nascimento.substr(0,10),
                    sexo: this.$store.state.pessoa_fisica.sexo,
                    dependentes: dep_aux,
                };

                this.info_dependentes = '';
                var dt_nsc = '';
                var j = 0;
                for (let i = 0, x =this.parametros_fator.dependentes.length; i < x; i++) { 
                  j = i + 1;
                  dt_nsc = this.parametros_fator.dependentes[i].data_nascimento.substr(8,2)+'/'+this.parametros_fator.dependentes[i].data_nascimento.substr(5,2)+'/'+this.parametros_fator.dependentes[i].data_nascimento.substr(0,4);
              
                  this.info_dependentes = this.info_dependentes + ' Dependente '+ j +' - Dt. Nasc.: ' + dt_nsc + ' Inválido: ' + this.parametros_fator.dependentes[i].invalido + ' Vitalício: ' + this.parametros_fator.dependentes[i].vitalicio + ';';

                } 

                if(this.info_dependentes == ''){
                    this.info_dependentes = 'Somente o titular';
                }

                this.SimulacaoAvancada();

                this.loading = false;
                
            })  
            .catch(error => {
                console.log(error);
                this.TrataErros(error);
            }); 
            
          }else{
            this.permite_simular = false;
            this.mensagem = this.titulo_pagina + " não está disponível para você";
            this.loading = false;
          }

          

          

        },

        //######################################
        //# Calculo das contribuicoes
        //###################################### 


        calculaVariavel(remuneracao, vrs, percentual){
            
            if(remuneracao > (vrs * 8)){
              var aux = (remuneracao - (vrs * 8)) * (percentual/100);
              return Math.round(aux * 100)/100;
            }else{
              return 0;
            }
        },

        calculaBasica(remuneracao){
            return remuneracao * 0.01;  
        },


        calculaAdm(contribuicao){
            return contribuicao * 0; 
        },

        calculaContribTotal(salario){

            var contrib_basica = 0;
            var contrib_variavel = 0;

            contrib_basica = this.calculaBasica(salario);
            contrib_variavel = this.calculaVariavel(salario, this.vrs, this.percentual); 
            
            return contrib_basica + contrib_variavel - this.calculaAdm(contrib_basica + contrib_variavel);
        },



        //######################################
        //# Chama o calculo do fator e calcula o benefício
        //###################################### 

        CalculaFator(){

          var plano = null;

          for(let i=0, len=this.$store.state.planos_usuario.length; i<len; i++){
            if(this.$store.state.planos_usuario[i].id_plano == 2 && (this.$store.state.planos_usuario[i].situacao =="ATIVO" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="BPD" || 
                                                                     this.$store.state.planos_usuario[i].situacao =="AUTOPATROCÍNIO" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="AUXÍLIO DOENÇA" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="ACIDENTE DE TRABALHO")){
              
                plano = this.$store.state.planos_usuario[i];

            }
          }
          
          this.fator = 0;
          this.loading_fator = true;

          this.parametros_fator.data_referencia = this.data_aposentadoria;

          if(isNaN(this.valor_espontanea)){
            this.valor_espontanea = 0;
          }
          if(isNaN(this.contribuicao_esporadica)){
            this.contribuicao_esporadica = 0;
          }

          let crescimento = 0;

          if(this.usa_crecimento){
            crescimento = this.crescimento_salarial;
          }

          var data = {
            //parametros: this.parametros_fator,
            id_pessoa: plano.id_pessoa,
            cota_familiar: this.parametros_fator.cota_familiar,
            cota_individual_pensao: this.parametros_fator.cota_individual_pensao,
            data_nascimento: this.parametros_fator.data_nascimento,
            data_aposentadoria: this.parametros_fator.data_referencia,
            data_referencia: this.data_referencia,
            dependentes: this.parametros_fator.dependentes,
            freq: this.parametros_fator.freq,
            id_plano: this.parametros_fator.id_plano,
            id_tabua: this.parametros_fator.id_tabua,
            maioridade: this.parametros_fator.maioridade,
            sexo: this.parametros_fator.sexo,
            taxa_juros: this.parametros_fator.taxa_juros,
            vrs: this.parametros_fator.vrs,
            remuneracao: this.remuneracao,
            saldo25: this.saldo25,
            saldo100: this.saldo100,
            percentual_variavel: this.percentual,
            contribuicao_esporadica: parseFloat(this.contribuicao_esporadica),
            valor_espontanea: this.valor_espontanea,
            saldoPatroc: this.saldoPatroc,
            idade_aposentadoria: this.idade_aposentadoria,
            rentabilidade_mensal: this.rentabilidade_mensal,
            crescimento_salarial: crescimento,
            parcela_vista_25: this.parcela_vista_25,
            parcela_vista_100: this.parcela_vista_100 
          }

          
          this.$API.post( '/api/simulador/ps2', data)
            .then(response => { 
              
                this.fator = response.data.fator;
                
                if(this.fator > 0){
                  //this.beneficio = this.saldo_projetado / this.fator; 
                  this.beneficio = response.data.beneficio;
                  
                  this.parcela_vista_25_projetada = response.data.parcela_vista_25_projetada;
                  this.parcela_vista_100_projetada = response.data.parcela_vista_100_projetada;
                  this.saldo_projetado = response.data.saldo;
                  this.saldo_resultante = response.data.saldo_resultante;
                  
                }else{
                  this.beneficio = 0;
                }

                this.prazo_pmp = response.data.pmp;
                this.aliquota_pmp = parseFloat(response.data.aliquota_pmp);
                this.ir_regressivo = response.data.ir_regressivo;
                this.ir_progressivo = response.data.ir_progressivo;
                this.aliquota_ir = response.data.aliquota_ir_progressivo;
                this.aliquota_efetiva = response.data.aliquota_ir_prog_efetiva;
                //console.log(response.data.aliquota_pmp);

                this.pagamento_unico = this.beneficio < (this.vrs/2);

                this.info_dependentes = '';
                let dt_nsc = '';
                let j = 0;
                for (let i = 0, x =this.parametros_fator.dependentes.length; i < x; i++) { 
                  j = i + 1;
                  dt_nsc = this.parametros_fator.dependentes[i].data_nascimento.substr(8,2)+'/'+this.parametros_fator.dependentes[i].data_nascimento.substr(5,2)+'/'+this.parametros_fator.dependentes[i].data_nascimento.substr(0,4);

                  this.parametros_fator.dependentes[i].idade_anos_na_apos = this.calcula_idade(this.parametros_fator.dependentes[i].data_nascimento, this.data_aposentadoria);
                  
                  if(this.parametros_fator.dependentes[i].idade_anos_na_apos < this.maioridade ){
                      this.info_dependentes = this.info_dependentes + ' Dependente '+ j +' - Dt. Nasc.: ' + dt_nsc + ' Inválido: ' + this.parametros_fator.dependentes[i].invalido + ' Vitalício: ' + this.parametros_fator.dependentes[i].vitalicio + ';';
                  }else{
                    if(this.parametros_fator.dependentes[i].vitalicio == "S" ){
                      this.info_dependentes = this.info_dependentes + ' Dependente '+ j +' - Dt. Nasc.: ' + dt_nsc + ' Inválido: ' + this.parametros_fator.dependentes[i].invalido + ' Vitalício: ' + this.parametros_fator.dependentes[i].vitalicio + ';';
                    }
                  }

                  

                } 

                if(this.info_dependentes == ''){
                    this.info_dependentes = 'Somente o titular';
                }

                this.crescimento_salarial = parseFloat(response.data.crescimento_salarial);
                this.parametros_fator.taxa_juros = parseFloat(response.data.taxa_juros);
                
                this.loading_fator = false;
                
            }) 
            .catch(error => {
                this.TrataErros(error);
            }); 

            
        },



        //######################################
        //# Confirma alteração de contribuição
        //###################################### 
        confirmaAlteracaoContribuicao(){

          this.form_valido = false;
          if(this.$refs.form.validate() ){

            let msg1 = "";

            if(this.alteracao_contrib == "e"){

                if(this.remuneracao > this.vrs * 8){
                  if(this.percentual < 15){
                    msg1 = "A contribuição espontânea mensal só é indicada para os participantes que já alcançaram o percentual máximo da contribuição variável pois essa última possui paridade contributiva (cada R$ 1,00 que você paga seu empregador também deposita R$ 1,00 para você), já na contribuição espontânea mensal isso não acontece. ";
                  }
                }
                
                this.mensagem = msg1+"Deseja realmente alterar sua contribuição espontânea mensal para "+Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(this.valor_espontanea)+" ?";
            }else{
                let texto_valor = "";
                if(this.simulador == 1){
                  texto_valor = this.percentual+"% ?";
                }else{
                  if(this.opcao_variavel==1){
                    texto_valor = Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(this.valor_variavel)+" ?";
                  }else{
                    texto_valor = this.percentual+"% ?";
                  }
                }

                this.mensagem = "Deseja realmente alterar sua contribuição variável para "+texto_valor;
                
            }
            this.cor_dialogo = "primary";
            this.sim_nao = true;
            this.dialog = true;
          }else{
              this.alerta = "Existem campos inválidos. Verifique os avisos acima";
              this.alerta_color = '#B00020';
              this.exibe_alerta = true;
            }

        },


        //######################################
        //# Altera a contribuição variável e a espontânea
        //###################################### 

        alterarContribuicao(){
          
          this.loading = true;

          var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          if(this.alteracao_contrib == "e"){

            var data = {
              id_pessoa: plano.id_pessoa,
              id_plano: plano.id_plano,
              valor: this.valor_espontanea
            }
            
            this.$API.post( '/api/arr/alterarespontanea', data)
            .then(response => {
                this.alerta_color = "green";
                this.alerta = "Contribuição espontânea alterada com sucesso!";
                this.exibe_alerta = true;   
                this.emailAlteraContrib(plano.id_pessoa, 0, this.valor_espontanea, 'E');            
                this.loading = false;
                
            })
            .catch(error => {
                this.TrataErros(error);
            });

          }else{

            var data = {
              id_pessoa: plano.id_pessoa,
              id_plano: plano.id_plano,
              percentual: this.percentual
            }
            
            this.$API.post( '/api/arr/alterarvariavel', data)
            .then(response => {

                this.alerta_color = "green";
                this.alerta = "Contribuição variável alterada com sucesso!";
                this.exibe_alerta = true;  
                this.emailAlteraContrib(plano.id_pessoa, this.percentual, this.valor_variavel, 'V');   
                this.loading = false;      
                //this.iniciaSimulador();
                
            })
            .catch(error => {
                this.TrataErros(error);
            });

          }



            
        },


        emailAlteraContrib(id_pessoa, percentual, valor, tipo){

          var data = {
            id_pessoa: id_pessoa,
            percentual: percentual,
            valor: valor,
            tipo: tipo
          }
            
          this.$API.post( '/api/arr/emailalteracontrib', data);

        },
        

        TrocaSimulador(){

        /*  if(this.idade < 65){
              this.idade_aposentadoria = 65;
          }else{
              this.idade_aposentadoria = this.idade +5;
          }

          if(this.idade > 79){
            this.idade_aposentadoria = this.idade;
            this.idade_min = this.idade;
            this.idade_max = this.idade + 5;
          } */

          this.percentual = this.percentual_original;
          this.usa_crecimento = true;
          this.parcela_vista_25 = 0;
          this.parcela_vista_100 = 0;
          this.contribuicao_esporadica = 0;

          if(this.simulador == 1){
              this.simulador = 2;
              this.titulo = 'Simulador Avançado';
              this.botao_simulador = 'Simulador Simplificado';
              this.opcao_variavel = 1;
              this.valor_variavel = this.calculaVariavel(this.remuneracao,this.vrs,this.percentual);
              this.valor_espontanea = this.valor_espontanea_original;



          }else{
              this.simulador = 1;
              this.titulo = 'Simulador Simplificado';
              this.botao_simulador = 'Simulador Avançado';
              this.valor_variavel = this.calculaVariavel(this.remuneracao,this.vrs,this.percentual);
              this.valor_espontanea = this.valor_espontanea_original;
              this.percentual_espontanea = this.valor_espontanea * 100 / this.remuneracao;

              this.SimulacaoAvancada();

          }
        },


        SimulacaoAvancada(){

            this.form_valido = false;
            if(this.$refs.form.validate() ){

              this.ano_aposentadoria = this.Ano_Nasc + this.idade_aposentadoria;
              this.data_aposentadoria = this.ano_aposentadoria + this.data_nascimento.substr(4,6);
              var data_aux = new Date(this.data_aposentadoria);
              data_aux.setMonth( data_aux.getMonth() + this.idade_meses );
              this.data_aposentadoria = data_aux.toISOString().substr(0,10);
              if(this.data_referencia > this.data_aposentadoria){
                this.data_aposentadoria = this.data_referencia;
              }
              this.ano_aposentadoria = this.data_aposentadoria.substr(0,4);
              this.mes_aposentadoria = this.data_aposentadoria.substr(5,2);
              

              this.parametros_fator.data_referencia = this.data_aposentadoria;
          

              if(this.opcao_variavel == 1){
                this.percentual = (this.valor_variavel /(this.remuneracao - (this.vrs*8))) * 100;
                this.percentual = this.percentual.toFixed(4);
              }else{
              /*  var aux = this.calculaVariavel(this.remuneracao, this.vrs, this.percentual * 10);
                if(aux > 0){
                  this.percentual = this.valor_variavel;
                }*/
              }
              
              if(this.valor_espontanea == '' || this.valor_espontanea == null){
                this.valor_espontanea = 0;
              }else{
                this.valor_espontanea = parseFloat(this.valor_espontanea);
              }
            
              //this.contribuicao_espontanea = parseFloat(this.valor_espontanea); 
              this.contribuicao_esporadica = parseFloat(this.contribuicao_esporadica);


              //this.ProjetaSaldo();
              this.CalculaFator();
            }else{
              this.alerta = "Não é possível simular. Verifique os avisos";
              this.alerta_color = '#B00020';
              this.exibe_alerta = true;
            }
            

        },

        alteraEspontanea: function(valor){
          this.percentual_espontanea = valor;
          let espontanea = this.remuneracao * this.percentual_espontanea /100;
          this.valor_espontanea = Math.floor(espontanea * 100) / 100; 
          
        },

        alteraVariavel: function(valor){
          this.percentual = valor;
          this.valor_variavel = this.calculaVariavel(this.remuneracao, this.vrs, this.percentual);
          
        },


        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>